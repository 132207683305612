
.k-progressbar .k-selected {
    background-color: #1F4690;
}

.k-radio:checked {
    border-color: #1F4690;
    background-color: #1F4690;
}

.kendo-form {
    font-weight: 400;
    color: #1F4690;
}

.form-submit {
    background-color: #1F4690;
    color: #D4EDFF;
}

    .form-submit:hover {
        background-color: #3a7cf7;
        color: #D4EDFF;
    }

