.login-form {
    /*background-color: rgb(255, 255, 255);*/
    width: 25%;
    /* height: 100%; */
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    top: 40%;
    box-shadow: #D8D8D8 !important 0px 4px 12px;
    font-size: 85%;
    border-color: #D8D8D8 !important;
    /* background*/
    /*background*/
}

.login-body {
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;
}


.btns {
    width: 100%;
}

.login-p {
    text-align: center;
    color: #000000 !important;
}

.border-input {
    outline: 0;
    background-color: transparent;
    border-width: 1px 1px 1px;
    display: block;
    width: 100%;
    border-color: #D8D8D8 !important;
}

    .border-input:focus {
        border-color: #2555B4 !important;
    }

.login-label {
    color: #000000 !important;
}
