.ProgramScheduleWizardFooter {
    padding: 8px 8px;
    box-sizing: border-box;
    border-width: 0px 0 0 0;
    border-style: solid;
    border-color: inherit;
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    clear: both;
}

ProgramScheduleSubWizardFooter {
    position: absolute !important;
    left: 30px;
    text-align: center !important;
}

#ScheduledfooterID {
    position: absolute !important;
    left: 30px;
    top: 610px !important;
    text-align: center !important;
    width: 100% !important;
}

#OrderFormfooterID {
    position: absolute !important;
    left: 30px;
    top: 800px !important;
    text-align: center !important;
    width: 100% !important;
}
#OrderFormfooterIDCounts {
    position: absolute !important;
    left: 30px;
    top: 760px !important;
    text-align: center !important;
    width: 100% !important;
}


.PorgramScheduleFooterSteps {
    display: none !important;
}

.ProgramScheduleSeparation {
    width: max-content !important;
}
