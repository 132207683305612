.login-background{
    background: linear-gradient(135deg,rgb(251, 225, 225), rgba(239, 255, 255, 0.323),rgb(237, 254, 250),rgba(224, 255, 249, 0.874),rgb(222, 252, 222));
}

.login-footer{
    /* position: fixed; */
   /* left: 0; */
   /* bottom: 0px; */
   position: absolute;
   width: 100%;
   height:80px;
   /* top: 81vh; */
   bottom: 0;
   /* top: 100px; */
   margin-bottom: 0px;
   background-color: rgb(255, 255, 255);
   color: white;
   text-align: center;
}
