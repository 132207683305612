.bg-image{
  position: absolute;
  height: 92vh;
  width: 100%;
  z-index: -1;
  /* background-image: url(./images/bucket"); */
  /*background-image: url("./images/bucket");*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.1;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
 width: 125px;
  height: 125px;
  border: 15px solid #f3f3f3;
  border-top: 15px solid #e4a7a7;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  z-index: 999999;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0,0,0,0.7);
  /* margin-left: 709px; */
  /* margin-top: 180px; */
  position: absolute;
  width: 100%;
}