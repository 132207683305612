

/* ************************** kENDO STYLES*******************/


/* ************************** kENDO DROP DOWN */


/* FOR DROPDOWN  */
.k-dropdownlist {
    /*background-color: rgb(141, 181, 255) !important;*/
    /*border: 2px rgb(83, 169, 255) solid !important;*/
}

.k-dialog-close {
    color: darkred !important;
    border-block: thick;
    border-radius: 1px;
}

.k-inner-input {
}

/*  KENDO MULTI SELECT */
.k-multiselect {
    line-height: 20px;
    border: 2px rgb(100, 159, 255) solid !important;
    background-color: rgb(177, 208, 255) !important;
}

.k-chip-content {
    /* line-height: 20px; */
    font-size: 16px !important;
}

/* *********** CHECKBOX ***************/

.k-checkbox:checked {
    background-color: #3ac118 !important;
}

/* ***************** KENDO MODAL  *******************/

.k-label {
    font-size: 16px !important;
}

.k-input {
    /* font-size: 16px !important; */
    /* background-color: rgb(252, 235, 255); */
}

.k-dialog-titlebar {
    background-color: rgb(233, 233, 233) !important;
}
/*BACKGROUND COLOR OF MODAL */
.k-form-fieldset {
    padding-bottom: 0px !important;
    bottom: 0 !important;
    /* background-color: rgb(255, 255, 255) !important; */
    background-color: transparent !important;
}

.k-dialog-content {
    margin-bottom: 25px !important;
}

.k-form .k-button {
    /* background-color: white !important; */
    /*position: relative !important;*/
}

.k-form-buttons {
    background-color: white !important;
    /*position: fixed !important;*/
    /*width: 58vw !important; */
    padding-top: 10px !important;
    border-top: 2px gray solid !important;
    margin-top: 10px !important;
    margin-bottom: -5px !important;
    z-index: 2 !important;
    padding-bottom: 0px !important;
    bottom: 19.4% !important;
}

@media(max-width:800px) {
    .k-form-buttons {
        width: 56vw !important;
    }
}

@media(max-width:550px) {
    .k-form-buttons {
        width: 53.5vw !important;
    }
}

/* KENDO BUTTON INCLUDING DROP DOWN ARROWS AND DATE PICKER */

.step-text-class {
    color: #000000 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.step-next-button {
    border-color: #D8D8D8 !important;
    background-color: #003087 !important;
    color: #FFFFFF !important;
    width: 72px !important;
}

.step-prev-button {
    border-color: #D8D8D8 !important;
    background-color: #003087 !important;
    color: #FFFFFF !important;
    width: 72px !important;
}

/* *******************  KENDO GRIDS ******************/

/* KENDO GRID HEADERS */
.k-grid-header, .k-grid-header-wrap {
    /* padding-right: 0 !important;*/
    /* width: 99%; */
    /*border-top-right-radius: 1rem !important;
    border: 1px !important;
    border-top-left-radius: 1rem !important;*/
    background: #d6d4d6;
}

.k-filter-row {
    background-color: rgb(236, 236, 236);
}

.k-grid-table {
    padding-right: 0 !important;
    border-bottom: 0.1px solid !important;
    border-bottom-color: #e7e4e4 !important;
}

.k-widget {
    /* border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;*/
    /* border-top-left-radius: 1rem !important; */
    /* border-top-right-radius: 1rem !important; */
}

.k-grid-header {
    /* background-color: rgb(72, 117, 230) !important; */
    margin-left:inherit !important;
}

.k-column-title {
    font-size: 1rem !important;
    color: #000000 !important;
    font-weight: 500 !important;
    white-space: pre-line !important;
}

.k-header {
    background-color: #d8d8d8 !important;
    justify-content: center !important;
}
/* FOR GRID PAGES IN GRID FOOTER */
.k-pager {
    color: #000000 !important;
    /* background-color: rgb(187, 107, 207) !important; */
    background-color: #d8d8d8 !important;
}

.k-link {
    /* color: aliceblue !important; */
}

.k-selected {
    color:#000000 !important;
    background-color: grey !important;
}

.k-pager-inumbers {
    background-color: grey !important;
}

.k-master-row:nth-child(even) {
    color: rgb(2, 2, 2) !important;
    background-color: rgb(245, 245, 245);
    /* background-color: rgb(255, 186, 172) !important; */
}

.k-master-row:nth-child(odd) {
    color: rgb(0, 0, 0) !important;
    background-color: rgb(253, 253, 253);
    /* background-color: rgb(248, 117, 117) !important; */
}

.k-master-row:nth-child(even):hover {
    background-color: rgb(230, 230, 230);
    /* background: rgb(113, 233, 181) ; */
}

.k-master-row:nth-child(odd):hover {
    background-color: rgb(235, 235, 235);
}

.add-button {
    border: 2px rgb(68, 229, 68) solid !important;
    background-color: rgb(222, 255, 214) !important;
}

.edit-button {
    border: 2px rgb(255, 225, 0) solid !important;
    background-color: rgb(242, 255, 179) !important;
    border-color: #D8D8D8 !important;
}

.delete-button {
    border: 2px  !important;
    background-color: rgb(255, 212, 212) !important;
}

.k-loader-canvas {
    margin: auto !important;
    margin-top: 4rem !important;
    font-size: 4rem !important;
}
/* KENDO MODAL HEADER */
.k-dialog-titlebar {
    background-color: rgb(236, 236, 236) !important;
    /* border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important;*/
    color: #000000 !important;
    font-weight: 700 !important;
}

.k-button-solid-primary {
    background-color: #003087 !important;
}

.k-dialog {
    /* border-radius: 1rem !important;*/
}

.k-grid-content {
    /*width: 100% !important;*/
    /*overflow-y: auto !important;*/
}
/* BORDER FOR KENDO FILTER BAR */
.k-filter-row th {
    border: 0 !important;
}

@media(max-width:450px) {
    .kendo-grid-body {
        padding: 5px !important;
    }

    .k-pager-numbers li {
        width: 20px !important;
    }

    .k-pager-info {
        width: 100px !important;
    }

    .k-pager-nav {
        width: 30px !important;
    }

    .k-pager-first, .k-pager-last {
        display: none !important;
    }

    .k-dropdownlist, .k-button-rectangle {
        display: none !important;
    }

    .display {
        display: inline !important;
    }

    .k-loader-canvas {
        margin-top: 5rem !important;
    }
}
/* ********************** EDIT MODAL  ****************** */
.k-dialog-content, .k-dialog {
  /*  width: 70vw !important;
    height: 75vh !important;*/
    height: 100% !important;
    width: 100% !important;
}

.kCommonAlertdialog {
    /*  width: 70vw !important;
    height: 75vh !important;*/
    height: auto !important;
    width: auto !important;
}

.quote-modal {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
}

/* ****************** COLOR FOR ALL KENDO COMPONENTS *****************/
.k-dropdownlist, .k-multiselect, .k-input {
    /* background-color: rgb(234, 236, 239) !important;*/
    border: 1px solid #D8D8D8 !important;
}

/* ******************* TEXT FOR ALL KENDO COMPONENTS  ***********************/
/*     .k-input is for all kendo inputs,   .k-input-value-text is for all kendo dropdowns input */
.k-input-value-text, .k-input {
    font-size: 15px !important;
    line-height: 22px !important;
    color: #000000 !important;
}

    .k-input:focus {
        border-color: #2555B4 !important;
    }
/*********************** ANIMATIONS ************************/
.hover-effect {
    transition: all 0.12s !important;
}

    .hover-effect:hover {
        transform: scale(1.05) !important;
        box-shadow: rgba(214, 214, 214, 0.692) 0px 5px 5px 3px !important;
    }
/* ************ CUSTOM CSS CLASSES ************** */
.label {
    font-size: 18px !important;
    margin-bottom: 0;
    line-height: 40px;
    color: rgb(51, 51, 51) !important;
    font-weight: 400 !important;
    font-family: sans-serif !important;
    font-style: normal !important;
}

.input-text {
    font-size: 15px !important;
    color: rgb(61, 61, 61) !important;
    padding: 0 20px;
    background: rgb(229 231 235);
    font-weight: 400 !important;
    font-family: sans-serif !important;
    font-style: normal !important;
}

    .input-text:focus {
        outline: 2px rgb(205, 205, 205) solid;
        background-color: #fff;
    }

.input-textarea {
    font-size: 16px !important;
    color: rgb(61, 61, 61) !important;
    font-weight: 400 !important;
    font-family: sans-serif !important;
    font-style: normal !important;
}
/* ***************** BUTTONS********************* */
.button-rounded {
    font-size: 14.5px !important;
    color: rgb(50, 50, 50) !important;
    font-weight: 400 !important;
    letter-spacing: 0.7px !important;
    padding: 5px 13px !important;
    background-color: rgb(240, 240, 240) !important;
    border-radius: 12px !important;
    font-family: sans-serif !important;
    font-style: normal !important;
    outline: none !important;
}

.button {
    font-size: 14.5px !important;
    color: rgb(50, 50, 50) !important;
    font-weight: 400 !important;
    letter-spacing: 0.7px !important;
    padding: 5px 13px !important;
    border: 1px rgb(181, 181, 181) solid !important;
    background-color: rgb(240, 240, 240) !important;
    font-family: sans-serif !important;
    font-style: normal !important;
    outline: none !important;
}

.button-circular {
    font-size: 14.5px !important;
    color: rgb(50, 50, 50) !important;
    font-weight: 400 !important;
    letter-spacing: 0.7px !important;
    padding: 5px 13px !important;
    background-color: rgb(240, 240, 240) !important;
    border-radius: 22px !important;
    font-family: sans-serif !important;
    font-style: normal !important;
    outline: none !important;
}

.button-save {
    color: #FFFFFF !important;
    border-color: #D8D8D8 !important;
    background-color: #003087 !important;
    font-size: 14.5px !important;
    font-weight: 400 !important;
    letter-spacing: 0.7px !important;
    padding: 5px 13px !important;
    border-radius: 5px !important;
    font-family: sans-serif !important;
    font-style: normal !important;
    outline: none !important;
}

.button-delete {
    font-size: 14.5px !important;
    color: rgb(255, 255, 255) !important;
    font-weight: 400 !important;
    letter-spacing: 0.7px !important;
    padding: 5px 13px !important;
    background-color: #e40046 !important;
    border-radius: 5px !important;
    font-family: sans-serif !important;
    font-style: normal !important;
    outline: none !important;
    border-color: #D8D8D8 !important;
}

.button-cancel {
    font-size: 14.5px !important;
    color: #000000 !important;
    font-weight: 400 !important;
    letter-spacing: 0.7px !important;
    padding: 5px 13px !important;
    background-color: #d6d7e2 !important;
    border-radius: 5px !important;
    font-family: sans-serif !important;
    font-style: normal !important;
    outline: none !important;
    border-color: #D8D8D8 !important;
}

.button-edit {
    font-size: 14.5px !important;
    color: rgb(255, 255, 253) !important;
    font-weight: 400 !important;
    letter-spacing: 0.7px !important;
    padding: 5px 13px !important;
    background-color: #003087 !important;
    border-radius: 5px !important;
    font-family: sans-serif !important;
    font-style: normal !important;
    outline: none !important;
    border-color: #D8D8D8 !important;
}

.paragraph {
    font-size: 16px !important;
    color: rgb(61, 61, 61) !important;
    font-weight: 400 !important;
    font-family: sans-serif !important;
    font-style: normal !important;
}

.normal-text {
    font-size: 14px !important;
    color: rgb(81, 81, 81) !important;
    font-weight: 400 !important;
    font-family: sans-serif !important;
    font-style: normal !important;
}


.anchor {
    font-size: 16px !important;
    color: rgb(0, 94, 255) !important;
    font-weight: 400 !important;
    font-family: sans-serif !important;
    font-style: normal !important;
    transition: all 0.2s !important;
}

    .anchor:hover {
        font-size: 17px !important;
        color: rgb(100, 92, 255) !important;
        font-weight: 400 !important;
        font-family: sans-serif !important;
        font-style: normal !important;
    }

.link {
    font-size: 18px !important;
    color: rgb(61, 61, 61) !important;
    font-weight: 400 !important;
    font-family: sans-serif !important;
    font-style: normal !important;
}

.font-bold {
    font-weight: 600 !important;
}

.font-extrabold {
    font-weight: 800 !important;
}

.font-small {
    font-size: 10px !important;
}

.font-medium {
    font-size: 16px !important;
}

.font-large {
    font-size: 20px !important;
}

.font-extralarge {
    font-size: 24px !important;
}

.prompt {
    font-family: sans-serif !important;
    color: rgb(103, 103, 103) !important;
    letter-spacing: 0.4px !important;
}

.primary-heading {
    font-family: sans-serif !important;
    font-size: 25px !important;
    text-transform: capitalize;
    font-weight: 700;
    color: #000000 !important;
}


/* tiles in editquoteRequest  */

.sok-tile-row {
    font-family: Arial;
    overflow: hidden;
}

.sok-tile {
    text-align: center;
    display: inline-block;
    font-size: 50px;
    color: white;
    /* background-color:#00a9de; */
    background-color: grey;
    width: 150px;
    height: 150px;
    line-height: 150px;
    margin-right: 10px;
    margin-bottom: 5px;
    overflow: hidden;
    cursor: pointer;
}

.sok-tile-name {
    text-align: left;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
    /* background-color:#0072c6; */
    background-color: lightgrey;
    color: black;
    height: 30px;
    width: 150px;
    position: relative;
    top: -50px;
}

.sok-tile:hover .sok-tile-name {
    height: 130px;
    position: relative;
    top: -150px;
    -webkit-transition-duration: .5s; /* Safari */
    transition-duration: .5s;
}

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.RSPBprogressBar {
    height: 17px !important;
}

.spantext {
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.fieldlabel {
    margin-bottom: 0px;
    margin-top: 10px;
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.Headlabel {
    color: #000000 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.wizardStepHeadLabel {
    font-weight: 700 !important;
    color: #000000 !important;
    font-size: 17px !important;
}


.k-form .k-form-field {
    margin-top: 0.3em !important;
    /*width:max-content !important;*/
}

k-disabled {
    background-color: #eaecef !important;
}

.k-picker-solid {
    background-color: #FFFFFF !important;
}

.RSPBprogressBar .RSPBprogression {
    background: #003087 !important;
}

.text-white {
    background-color: #003087 !important;
}

/*Kendo Stepper Current Class*/
.k-step-current .k-step-indicator {
    background: #003087 !important;
    color: #FFFFFF !important
}

.k-step-label .k-step-current .k-step-indicator {
    color: #000000 !important
}

/*Kendo Stepper Success Class*/

.k-step-success .k-step-indicator {
    background: #047E01 !important;
    color: #FFFFFF !important
}

.k-step-label .k-step-success .k-step-indicator {
    color: #000000 !important
}

/*Kendo Stepper Error Class*/
.k-step-error .k-step-indicator {
    background: #BE3030 !important;
    color: #FFFFFF !important
}

.k-step-label .k-step-error .k-step-indicator {
    color: #000000 !important
}

/*Kendo Stepper Normal Class*/
.k-step-label .k-stepper .k-step-indicator {
    color: #777777 !important
}

.kendo-stepper {
    color: #777777;
}

.profile-button {
    color: #000000 !important;
}


.WizardFooter {
    padding: 8px 8px;
    box-sizing: border-box;
    border-width: 0px 0 0 0;
    border-style: solid;
    border-color: inherit;
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    overflow: hidden;
    clear: both;
    justify-content: center !important;
}

.SubWizardFooter {
    position: absolute !important;
    left: 30px;
}


.BackToDashboard {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 148px;
    height: 40px;
    background: #FFFFFF;
    /* SchoolKidz Blue 100 */
    border: 1px solid #003087;
    color: #003087 !important;
    border-radius: 5px;
}

.AgreementText {
    font-size: 16px !important;
    color: #003087 !important;
    font-weight: 400 !important;
    font-family: sans-serif !important;
    font-style: normal !important;
}

.pdf-document {
    display: flex;
    flex-wrap: wrap;
}

.pdf-page {
    /* width: 100px;
  height: 100px; */
    margin: 0 10px;
    box-shadow: 10px green;
    border: 1px solid red;
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__annotations {
    display: none;
}

.k-grid-header {
    padding: 0 !important;
}

.k-grid-content {
    overflow-y: visible !important;
    /*overflow-x:scroll !important;*/
}

.k-grid {
    width: fit-content !important;
}

.PageStyle{
    width:fit-content !important;
    margin:auto !important;
}

.modalStyle {
    width: fit-content !important;
    margin: auto !important;
}

.radiofieldlabel {
    margin:auto !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.k-stepper .k-step-label .k-step-text {
    max-width:calc(13em-20px) !important;
}

