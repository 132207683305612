.camera-icon-profile{
    width: 2rem;
    font-size: 30px;
    font-weight: 500;
  }

  .askchoice-profile{
    position: absolute;
    width: 50vw;
    height: 50vh;
    }
