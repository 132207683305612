  .profile-img {
    height: 30px;
    width: 30px;
}

.profile-img-big {
    height: 10rem;
    width: 10rem;
}

.profile-view {
    /* height: 17rem;
    width: 15rem; */
}

.profile-div {
    height: 100%
}

.profile-view {
    z-index: 10;
}
.aligntomiddle{
    align-self: center;
}


/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .icons {
        text-align: justify;
    }
}
@media (min-width:760px) {
    .askchoice{
    position: absolute;
    width: 50vw;
    right: 100%;
    height: 50vh;
    }
    .uploaded-image , .take-picture{
        font-size: 1.2rem;
        margin: 1rem auto;
        padding: 5px;
        width: max-content;
    }
    .video{
        width: 36vw;
        margin: auto;    
        border: 4px rgb(177, 177, 177) solid;
        padding: 2px;
        right: 50vw;
        transform: translateX(50%);
        background-color: rgb(237, 237, 237);
    }
    #player{
        width: 100%;
    }
    #canvas{
        /* position: absolute; */
        width: 100%;
        height:100%;
    }
    
    
}

.screen-div{
    animation: pageload 0.8s ease-out;  
  }
  .navigation-bar{
  /* background-image: url("../../../images/header-bg.fb71804a.svg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height:70px !important;
    /*background-image: linear-gradient(90deg,rgb(255, 60, 60),rgb(58, 58, 250));*/
  }
  .nav-div{
    display: grid;
    grid-template-columns:250px 1fr;
    max-height: 85px;
    background-color: #13709e;
  }
  .icons{
    display: flex;
    position: relative;
    align-items: center;
    max-height: 85px;
    gap: 20px;
    right: 60px;
  }
  .logo{
    width: 200px;
    background-color: rgb(239, 194, 194);
    /* background-color: #13709e; */
    height: 85px;
    padding:10px ;
    box-sizing: border-box;
    /* background-image: url("../public/logo.png"); */
    background-repeat: no-repeat;
    background-size: auto;
  }
  .image-div{
    /* background-image: url("../public/demoimg.jpg"); */
    background-position: center;
    background-size: cover;
    border-radius: 1rem;
    width: 35px;
    height: 35px;
    margin-left: 20px;
  }
  .nav-right{
    display: grid;
    grid-template-columns: 1fr;
  }
  .icon-message,.icon-bell{
    fill: #fff;
    position: relative;
  }
  .message-badge , .message-badge-bell{
  background-color: rgb(153, 221, 128);
  position: absolute;
  border-radius: 4px;
  color: #fff;
  width: 15px;
  text-align: center;
  top: 20px;
  height: 20px;
  left: 50px;
  z-index: 2;
  }
  .message-badge-bell{
    left: 16px;
    background-color: rgb(195, 99, 99);
  }
  .hide-button{
    display: none;
  }
  .back-icon{
    line-height: 2.2rem !important;
  }
  .horizontal-loader{
   overflow: hidden; 
  }
  /* **************************  LOADER ************************/
  .horizontal-loader::before{
    content: "";
  position: relative;
  float: left;
  width: 0.5px;
  height: 10px;
  z-index: -1;
  overflow: hidden;
  color: #13709e;
  background-color: rgb(53, 0, 138);
  animation: loader 3s;
  }
  @keyframes loader {
    0%{
        transform: scale(0);
    }
    60%{
        transform: scale(710);
    }
    70%{
        transform: scale(1500);
    }
    85%{
        transform: scale(1800);
    }
    100%{
        display: none;
        transform: scale(2800);
    }
  }
  /* ************************************************* */
  /* ***************      ANIMATIONS    ************************* */
  @keyframes pageload {
    0%{
      transform: translate(-18%);
      opacity: 0;
    }
    100%{
      transform: translate(0);
      opacity: 1;
    }
    
  }
  /* ***************************IMAGE*************** */
  .choose-image{
    background-color: transparent;
    width: 1px;
    height: 1px;
    
  }
  .image-div{
    top: 1.1rem;
    position: absolute;
    overflow: hidden;
    left: 8.5rem;
    width: 3.1rem;
    max-height: 30px;
    background-color: rgb(116, 45, 45);
    background-color: transparent;
  }
  .camera-icon{
    width: 2rem;
    right: 6px;
    font-size: 30px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    position: absolute;
    z-index: 2;
  }
  #canvas{
    height: min-content;
  }
  .video{
    background-color: rgb(237, 237, 237)

    /* height:100%; */
  }
  .cam-btns{
    margin-top:1.4rem;
  }