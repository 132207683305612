/******************Login************/
html{
    font-size: 100%;
}
body{
   
    
}
.login-form {
    /*background-color: rgb(255, 255, 255);*/
    width: 25%;
    /* height: 100%; */
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    top: 40%;
    box-shadow: rgba(216, 216, 216, 1) 0px 4px 12px;
    font-size: 85%;
    border-color: #D8D8D8 !important;
    /* background*/
    /*background*/
}


.signaturepad{
    height: 200px;
    width: 350px;
    position: relative;
    right: 0px;
    margin: 0 auto;
    
    /* top: 40vh; */
    /* border: 1px solid green; */
    background-color: rgb(255, 255, 255);
    
}
.canvas{
    /* height: 150px;
    width: 348px; */
    position: relative;
    margin: auto;
    border: 1px solid green;
    background-color: rgb(244, 244, 244);
}

.container-full{
    width: 80%;
    margin: auto;
}
.rounded{
    border-radius: 0px !important ;
}

.btns{
    width: 100%;
}

.border-input{
    outline: 0;
    /* background-color:transparent; */
    border-width: 1px 1px 1px;
    display: block;
    width: 100%;
    border-color: #D8D8D8 !important;
}

    .border-input:focus {
        border-color: #2555B4 !important;
    }
.main{
height: 100%;

}

.hidden-button{
    display: none;
}
.warn{
    display: none;
}
.toemailsent{
    display: none;
}
@media (max-width: 1300px){
    .container{
    width: 32%;

    }
}
@media (max-width: 1000px){
    .container{
        width: 40%;
        /* padding: 0px 1px; */
    }
    @media (max-width: 750px){
        .container{
            width: 60%;
        }
    }
}@media (max-width: 550px){
    .container{
        width: 90%;
    }
}
/* @tailwind base;

@tailwind components;

@tailwind utilities; */