/* *****ICONS CSS***** */
.material-symbols-circle {
    color: #ffffff;
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48
}

.material-symbol-blue {
    color: #5588ff;
    text-shadow: 1px 1px 2px black;
}

.material-symbol-red {
    color: #ff4040;
    text-shadow: 1px 1px 2px black;
}

.symbol-dashboard-blue {
    /* background-color: #003087 !important;
    background: #FFFFFF !important;*/
    position: absolute;
    left: 17%;
    right: 4%;
    top: 4%;
    bottom: 0%;
}

.material-symbol-dashboard-blue {
}

.material-symbol-dashboard-red {
    border-radius: 50%;
    width: 69px;
    height: 69px;
    background-color: #fafafa;
    color: #ff4040;
    padding: 3px;
}

/* ******************* */

.bg-image {
    position: absolute;
    height: 92vh;
    width: 100%;
    z-index: -1;
    /*background-image: url("../../../images/bucket");*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.1;
}

.card-body-red {
    background-color: #ff4040 !important;
}

.card-body-brown {
    background-color: rgb(238, 77, 198) !important;
}

.card-link-brown {
    background-color: rgb(172, 9, 132);
}

.card-link-red {
    background-color: #de0000;
}

.card-body-teal {
    background-color: #00a9c0;
}

.card-link-teal {
    background-color: #0d8fa0;
}

.card-body-pink {
    background-color: #d914c9;
}

.card-link-pink {
    background-color: #b510af;
}

.card-body-green {
    background-color: #00cb00 !important;
}

.card-link-green {
    background-color: #00b100;
}

.card-body-blue {
    background-color: #003087 !important;
}

.card-link-blue {
    background-color: #001D51 !important;
    text-decoration: underline !important;
}

.card-body-orange {
    background-color: #f5890d !important;
}

.card-link-orange {
    background-color: #f97300;
}

.card-bg {
    background-color: rgb(255, 123, 0) !important;
}

.button-hover-effect {
    overflow: hidden;
    position: relative;
    z-index: 0;
}

.button-hover-effect-blue::before, .button-hover-effect-red::before, .button-hover-effect-green::before, .button-hover-effect-orange::before, .button-hover-effect-teal::before, .button-hover-effect-pink::before {
    content: "";
    display: none;
    /* position: absolute; */
    float: left;
    position: inherit;
    width: 1px;
    height: 1px;
    z-index: -1;
}

.button-hover-effect-blue:hover::before, .card-body-blue:hover .button-hover-effect-blue::before {
    display: block;
    animation: expand-button 1s;
    transform: scale(1500);
    background-color: #001D51 !important;
}

.button-hover-effect-red:hover::before, .card-body-red:hover .button-hover-effect-red::before {
    display: block;
    animation: expand-button 1s;
    transform: scale(1500);
    background-color: #c21010;
}

.button-hover-effect-teal:hover::before, .card-body-teal:hover .button-hover-effect-teal::before {
    display: block;
    animation: expand-button 1s;
    transform: scale(1500);
    background-color: #0a7f91;
}

.button-hover-effect-green:hover::before, .card-body-green:hover .button-hover-effect-green::before {
    display: block;
    animation: expand-button 1s;
    transform: scale(1500);
    background-color: #098400;
}

.button-hover-effect-orange:hover::before, .card-body-orange:hover .button-hover-effect-orange::before {
    display: block;
    animation: expand-button 1s;
    transform: scale(1500);
    background-color: #d63114;
}

.button-hover-effect-pink:hover::before, .card-body-pink:hover .button-hover-effect-pink::before {
    display: block;
    animation: expand-button 1s;
    transform: scale(1500);
    background-color: #971097;
}

@keyframes expand-button {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1500);
    }
}
/* *********************PROFILE PAGE ***************/
