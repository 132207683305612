
.scroll-prompt {
    width: 160px;
}

    .scroll-prompt .scroll-prompt-arrow-container {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -18px;
        animation-name: arrowbounce;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
    }

    .scroll-prompt .scroll-prompt-arrow {
        animation-name: arrowopacity;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
    }

        .scroll-prompt .scroll-prompt-arrow:last-child {
            animation-direction: reverse;
            margin-top: -6px;
        }

        .scroll-prompt .scroll-prompt-arrow > div {
            width: 36px;
            height: 36px;
            border-right: 8px solid #bebebe;
            border-bottom: 8px solid #bebebe;
            transform: rotate(45deg) translateZ(1px);
        }


@keyframes arrowopacity {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0.1;
    }

    20% {
        opacity: 0.2;
    }

    30% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.5;
    }

    60% {
        opacity: 0.6;
    }

    70% {
        opacity: 0.7;
    }

    80% {
        opacity: 0.8;
    }

    90% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
    }
}

@keyframes arrowbounce {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(3px);
    }

    20% {
        transform: translateY(6px);
    }

    30% {
        transform: translateY(9px);
    }

    40% {
        transform: translateY(12px);
    }

    50% {
        transform: translateY(15px);
    }

    60% {
        transform: translateY(18px);
    }

    70% {
        transform: translateY(21px);
    }

    80% {
        transform: translateY(24px);
    }

    90% {
        transform: translateY(27px);
    }

    100% {
        transform: translateY(30px);
    }
}
